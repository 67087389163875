import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;

    a {
        color: currentColor;
    }
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Letní Holandsko a Belgie</Title>
            <Info>
                <Author>Julia</Author>
                <Date>16. 7. 2023</Date>
            </Info>

            <p>Většina z nás vyráží v létě k moři. V létě 2022 jsem se však rozhodla pro menší změnu. Místo válení na pláži a koupání v moři, jsme se rozhodli vydat po zemích Beneluxu.</p>
            <p>Plán byl následující - přílet do <b>Charleroi</b> (Brusel) - 1 noc v <b>Bruselu</b> - 2 noci v <b>Bruggách</b> - 3 noci <b>Rotterdam</b> (včetně návštěvy města <b>Haag</b>) - 2 noci v <b>Amsterdamu</b> - návrat do Charleroi.</p>
            <p>Veškeré přejezdy jsme řešili až na místě - vesměs vlaky, případně autobusy flixbus. Samozřejmě, výhodnější je řešit lístky na vlaky s předstihem. Člověk tím může dost ušetřit!</p>
            <p>My jsme ale měli menší komplikace před odletem, proto jsme to nechali na poslední chvíli.</p>

            <h2>Brusel</h2>
            <p>Kosmopolitní metropole, která je <b>jedním z hlavních symbolů Evropské unie</b>. Město může na někoho na první pohled působit nevlídně. Buďte připraveni na velmi multikulturní prostředí, které se ale mně osobně zdá být velmi zajímavé. Brusel si mě získala především svými spletitými uličkami, množstvím různých kaváren, restaurací a čokoládoven. Své si zde najdou i nadšenci památek světového významu - <b><a href="https://goo.gl/maps/qicP8myigpBhuM5F9">Atomium</a></b>, <b><a href="https://goo.gl/maps/2R2bTrEb2UdeeUWw9">Berlaymont</a></b>, <b><a href="https://goo.gl/maps/Q3UJrQ4NpCYLNi7v5">Manneken Pis</a></b> a mnoho dalšího.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-1.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>Velmi často slýchám, že Brusel někoho zklamal nebo nenadchl. Ano, není to Paříž, Řím nebo Praha. Ale! Pokud mu dáte šanci a navíc víte, kam jít - <b>může vás mile překvapit</b>! </p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/holand/holand-2.jpeg" alt=""></StaticImage>
                    <StyledFigcaption></StyledFigcaption>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/holand/holand-3.jpeg" alt=""></StaticImage>
                    <StyledFigcaption></StyledFigcaption>
                </StyledFigure>
            </DoubleImage>
            <h2>Bruggy</h2>
            <p>Bruggy, známé také jako <b>"Benátky severu"</b>, je malebné historické město v Belgii, které se nachází ve vlámském regionu. Toto překrásné město je plné starobylé architektury, malebných kanálů, historických kostelů a úzkých dlážděných uliček.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-4.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>Jedno z <b>nejlépe dochovaných středověkých měst v Evropě</b> jehož historické centrum bylo zapsáno na seznamu světového dědictví <b>UNESCO</b>.</p>
            <p>Je to zároveň domov mnoha významných kostelů, jako je katedrála svatého Salvátora a bazilika Svaté krve. Tyto kostely jsou bohatě zdobené a mají velkou historickou hodnotu.</p>
            <p>Těšit se můžete na slavné <b>belgické pivo</b> (také mnoho druhů <b>bezlepkového piva</b>), <b>čokoládu, křupavé brambůrky</b> a další lahůdky. Místní restaurace a kavárny nabízejí autentické belgické speciality, <b>včetně belgických hranolek</b>, které nabízejí stánky na každém rohu.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-5.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/holand/holand-6.jpeg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/holand/holand-7.jpeg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Centrum městečka, a to nejdůležitější můžete projít za jeden den. My jsme si druhý den půjčili kolo a vyjeli na výlet k moři do města <b><a href="https://goo.gl/maps/rT8LYz6YEH8Df2Gn7">Bredene</a></b>. Cesta vede podél kanálu <b>Gent - Brugge - Oostende</b> a trvá cca 2,5 hodiny, podle toho, jak často stavíte a jak moc se kocháte. Je to vlastně příjemná rovinka - asi jako všechny cyklistické trasy zde. Strávili jsme část dne u vody a mezi písečnými dunami.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-8.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <h2>Rotterdam</h2>
            <p>Druhé největší město Nizozemska a jeden z nejvýznamnějších přístavů na světě. Město má přezdívku  <b>"Gateway to Europe"</b> (Brána do Evropy), protože zde přichází a odchází obrovské množství nákladních lodí. Rotterdam je moderní a inovativní metropole, která se pyšní unikátní architekturou, kulturní scénou a energickou atmosférou.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-9.jpeg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-10.jpeg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>My jsme si toto město zamilovali především díky moderní architektuře, která vznikla po druhé světové válce, během níž bylo historické jádro města téměř kompletně zničeno. <b>Místo obnovy historických budov se rozhodli vytvořit novou tvář města s inovativními a neobvyklými stavbami.</b> Mezi ikonické budovy patří například Erasmus Bridge, majestátní most, který překlenuje řeku Nieuwe Maas, nebo De Rotterdam, trojice mrakodrapů spojených na vrcholu, které patří mezi nejvyšší budovy v Nizozemsku.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-11.jpeg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-12.jpeg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p><b><a href="https://goo.gl/maps/S6DVTdVepeH1fXkH6">Markthal</a></b> - moderní obchodní centrum je také architektonickým skvostem s vnitřní stěnou pokrytou obrovskými malbami. <b>Rotterdamský přístav</b> je další zajímavou atrakcí, kterou si můžete prohlédnout. Je to jeden z největších přístavů na světě a nabízí vám možnost vidět obrovské kontejnerové lodě, zásobovací plavidla a doky. Ten jsme bohužel nestihli, ale rozhodně to mám na svém seznamu pro příští návštěvu.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-13.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-14.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>Rotterdam je také rájem pro milovníky designu a módy. Nachází se zde mnoho butiků, obchodů s designovým nábytkem a výstavních prostor, které nabízejí inovativní a stylové produkty.</p>
            <h2>Haag</h2>
            <p>Hlavní město Nizozemska a jedním jedno z nejvýznamnějších politických a správních center země. Je situováno na západním pobřeží a nabízí nádhernou kombinaci historie, kultury a moderního života. <b>Sídlí zde nejdůležitější soudní orgán OSN</b>, který se zabývá řešením mezinárodních sporů a prosazováním lidských práv. </p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-15.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>V Haagu jsme strávili jeden den. Oběd jsme si dopřáli ve <b><a href="https://goo.gl/maps/W3BLm5vy2U3FxgR97">Foodhallen</a></b> a následně si prošli centrum města a podívali se také k moři, kolem kterého vedle hlavní promenáda <b>De Pier</b>.
                Městečko na nás zapůsobilo velmi příjemně. Velké množství kaváren, obchůdků a galerií.
                </p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-16.jpeg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-17.jpeg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <h2>Amsterdam</h2>
            <p>Jeden z nejpopulárnějších turistických cílů v Evropě. Bohaté svou historií, specifickými uličkami, známými muzei a vodními kanály. Toto město jsem poprvé navštívila před několika lety s mým taťkou. Tato návštěva byla pro mě jakýsi flashback.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/holand/holand-18.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>Procházet se po místech, kudy jsem tenkrát jezdila s taťkou na kole, bylo pro mě velmi emotivní a neslo to se sebou i spoustu příjemných vzpomínek.</p>
            <p>Půjčili jsme si zde kola na 2 dny kousek od našeho hotelu. Ubytovaní jsme byli v <b><a href="https://www.booking.com/Share-UrYtWS">The Social Hub Amsterdam City</a></b>. Byl to příjemný hotýlek studentského moderního typu. K dispozici snídaně, možnost objednat si také oběd nebo večeři. Veškerá vybavenost.</p>
            <p>Dva dny jsme zde brázdili ulicemi města a parky a nasávali lokální atmosféru. Nevynechali jsme ani místní uličku hamby - <b>Red Light Street</b>. Večer to tu opravdu žije, je to zároveň místo, které se nemusí líbit každému. Za mě osobně jednou stačilo. Viděla jsem to, ale rozhodně to není typ atrakcí, které bych na svých dovolených vyhledávala.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-19.jpeg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/holand/holand-20.jpeg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Co rozhodně doporučuji, jsou místní muzea - <b>Dům Anny Frankové</b>, <b>Van Gogh muzeum</b> a mnoho dalších.</p>
            <p>Obecně nás tato dovolená v letním období nevyšla nejlevněji. Ale upřímně, pokud se člověk vydá do těchto destinací v létě, tak musí počítat s tím, že to nebude levné. Nešetřili jsme, ale zároveň jsme neutráceli nějak extra za nesmysly. Ale my jsme si to chtěli tak nějak normálně užít. Ubytování jsme si vybírali v takovém průměru, jídlo jsme řešili někdy formou nějakého salátu v supermarketu, a zároveň jsme navštěvovali i restaurace.</p>
            <p>A celkové moje pocity? Jsou místa, kam se mi stačí podívat jednou, maximálně 2x za život. Je zde mnoho k vidění, ale já spíš osobně preferuji méně rozvinuté země, které mají pro mě tak trochu jiné a zajímavější kouzlo. Ale je to s každou zemí jiné. Do Itálie a do Rigy se například vracím ráda opakovaně.</p>
            <p>Pokud Vás zajímá víc tipů na tyto města, ozvěte se nám.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
